import React, { Component } from 'react';
import "./NavBar.css"
import {Link} from "react-router-dom"
import logo from "../../assets/logo_horizontal.png"
import FontAwesome from "react-fontawesome";


class NavBar extends Component {

    state = {
        show:false
    }

    render() {
        let {show} = this.state
        return (
            <div>
            <div className="nav">
                <Link to="/">
                    <img src={logo} alt=""/>
                </Link>
                <div className="pestanas">
                    <Link to="/inicio">
                        <p>Inicio</p>
                    </Link>
                    <Link to="/quienes-somos-horizonte">
                        <p>Quiénes somos</p>
                    </Link>
                    <Link to="/que-pensamos-horizonte">
                        <p>Qué pensamos</p>
                    </Link>
                    <Link to="/que-hacemos-horizonte">
                        <p>Qué hacemos</p>
                    </Link>
                    <Link to="/contacto">
                        <p>Contacto</p>
                    </Link>
                </div>
                <div
                    onClick={()=>this.setState({show:!show})}
                    className={"anvorguesa"}>
                    <FontAwesome name="bars" />
                </div>

                 </div>
                <div className={show ? "mobile-menu":"mobile-menu menu-off"}>
                    <Link to="/quienes-somos-horizonte">
                        <p>Quiénes somos</p>
                    </Link>
                    <hr/>
                    <Link to="/que-pensamos-horizonte">
                        <p>Qué pensamos</p>
                    </Link>
                    <hr/>
                    <Link to="/que-hacemos-horizonte">
                        <p>Qué hacemos</p>
                    </Link>
                    <hr/>
                    <Link to="/contacto">
                        <p>Contacto</p>
                    </Link>
                </div>
        </div>

        );
    }
}

export default NavBar;