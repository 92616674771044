import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import Slide from "./components/Slide"
import WhatWeDo from "./components/WhatWeDo";
import WhatWeThink from "./components/WhatWeThink";
import Contact from "./components/Contact";
import WhatWeAre from "./components/WhatWeAre";
import Inicio from "./components/Inicio";
import QuienesSomos from "./components/en/QuienesSomos";
import QueHacemos from "./components/en/QueHacemos";
import QuePensamos from "./components/en/QuePensamos";
import Contacto from "./components/en/Contacto";

const Routes = () => (
    <Switch>
        <Route exact path="/" component={Slide} />
        <Route path="/inicio" component={Home}/>
        <Route path="/home" component={Inicio}/>
        <Route path="/quienes-somos-horizonte" component={WhatWeAre}/>
        <Route path="/que-hacemos-horizonte" component={WhatWeDo}/>
        <Route path="/que-pensamos-horizonte" component={WhatWeThink}/>
        <Route path="/contacto" component={Contact}/>
        <Route path="/home" component={Inicio}/>
        <Route path="/what-we-are" component={QuienesSomos}/>
        <Route path="/what-we-do" component={QueHacemos}/>
        <Route path="/what-we-think" component={QuePensamos}/>
        <Route path="/contact" component={Contacto}/>
    </Switch>
);
export default Routes;