import React, { Component } from 'react';
import '../Home.css';
import Nav from "../nav/Nav";
import FooterTest from "../footer/FooterTest";
import arrow from "../../assets/Line 2.svg";

class Contacto extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {

        return (
            <div >

                <Nav/>
                <div id="contacto" className="slide-home">
                    <div className="cubierta">
                        <div className="box-dos"></div>
                        <div className="box-white">
                            <h2 className="titulos">Contact us</h2>
                            <p className="txt" >Complete the form and we will contact you as soon as possible</p>
                            <a href="#family">
                                <img src={arrow} alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="contacto">
                    <div className="mapa-contacto">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.7554914116868!2d-99.22086948558423!3d19.42296768688976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2018eb4774df1%3A0x3f1117afd646b71f!2sSierra%20Nevada%20755%2C%20Lomas%20de%20Chapultepec%2C%2011000%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1568174374531!5m2!1ses-419!2smx"
                            frameBorder="0" style={{border:"0"}} allowFullScreen=""></iframe>

                    </div>
                    <div className="form-contacto">
                        <h2 className="titulos" style={{marginTop:"0"}}>Contact us</h2>
                        <div className="form_ctc">
                            <div>
                                <input type="text" placeholder="Name"/>

                            </div>
                            <div>
                                <input type="text" placeholder="E-mail"/>

                            </div>
                            <div>
                                <input type="text" placeholder="Phone"/>

                            </div>
                            <div>
                                <textarea type="text" placeholder="Message"/>

                            </div>
                            <button className="btn-send">SUBMIT</button>
                        </div>
                        <p>Or call us: +52 (55) 4624 0156</p>
                    </div>

                </div>
                <FooterTest/>
            </div>
        );
    }
}

export default Contacto;