import React, { Component } from 'react';
import '../Home.css';
import arrow from "../../assets/Line 2.svg"
import Nav from "../nav/Nav";
import FooterTest from "../footer/FooterTest";
import video1 from "../../assets/Pequeñas Grandes Cosas (5to Corte).mp4";
import video2 from "../../assets/Una Gran Idea (5to Corte).mp4";
import video3 from "../../assets/Somos Nosotros (5to Corte).mp4";
import video4 from "../../assets/Huellas (5to Corte).mp4";

class QueHacemos extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {
        return (
            <section >
                <Nav/>
                <div id="do" className="slide-home">
                    <div className="cubierta">
                        <div className="box-dos"></div>
                        <div className="box-white">
                            <h2 className="titulos">What we do</h2>
                            <p className="txt" >Our methodology is focused on taking steps to ensure that wealth lasts
                                harmoniously over generations.</p>
                            <a href="#proceso">
                                <img src={arrow} alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <section id="proceso" className="oferta">
                    <h2 className="titulos">Our Process</h2>
                    <p className="txt">We promote togetherness among present and future generations, designing
                        customized estate plans which provide peace of mind for all concerned, leaving an
                        authentic legacy which transcends the purely economic sphere.</p>
                    <div className="box-product">
                        <div className="descript-product">
                            <h2>Generational Development Plan</h2>
                            <h3>What are we looking for?</h3>
                            <p>We train new generations of a family in family wealth management, seeking to
                                transmit family values and a sense of ownership.</p>
                            <h3>How do we do it?</h3>
                            <p>We design a customized plan for each family, using a methodology based on the
                                principles of: Inclusion, Identity, Learning, and Trust.
                            </p>
                            <p>
                                We implement the transmission of knowledge and values in different formats: from
                                workshops and presentations to mentoring, meetings, and personal interviews.</p>
                            <h3>What do we accomplish?</h3>
                            <p>We teach the new generations to be aware of what managing family wealth
                                entails, and their duty and responsibility to preserve and increase it.</p>
                        </div>
                        <div className="name-product">
                            <video  controls loop id="video-product">

                                <source src={video1} type="video/mp4"/>

                            </video>

                        </div>
                    </div>
                    <div className="box-product">
                        <div className="name-product">
                            <video  controls loop id="video-product">

                                <source src={video2} type="video/mp4"/>

                            </video>


                        </div>
                        <div className="descript-product" style={{padding:"0 0 0 4%"}}>
                            <h2>Plan Hereditario 360®</h2>
                            <h3>What are we looking for?</h3>
                            <p>We organize a family’s estate, strengthening family ties to increase the family’s
                                peace of mind and ensure maximum effectiveness in its implementation.</p>
                            <h3>How do we do it?</h3>
                            <p>We apply the principles of Empathy, Harmony, Responsibility, and Protection with
                                an innovative methodology which combines various fields (management, law,
                                finance, psychology, sociology, anthropology, etc.).
                            </p>

                            <h3>What do we accomplish?</h3>
                            <p>We organize estate processes so that they promote family harmony and
                                togetherness.</p>
                        </div>
                    </div>
                    <div className="box-product">

                        <div className="descript-product">
                            <h2>Family <br/>Togetherness Plan</h2>
                             <h3>What are we looking for?</h3>
                            <p>We promote togetherness among different members, generations, and branches of
                                a family, to transform family wealth into a solid legacy.</p>
                            <h3>How do we do it?</h3>
                            <p>We use workshops and sessions to develop and establish, formally and functionally,
                                broad family agreements to implement protocols or foundational charters.
                            </p>
                            <p>We build awareness of the importance of considering the principles of Respect,
                                Responsibility, Solidarity, and Togetherness.</p>

                            <h3>What do we accomplish?</h3>
                            <p>We build a stronger relationship among members of different generations to make
                                generational transitions simple and effective, to ensure that business or
                                investments benefit from a common vision, which is the product of a cohesive and
                                harmonious family.</p>
                        </div>
                        <div className="name-product">
                            <video  controls loop id="video-product">

                                <source src={video3} type="video/mp4"/>

                            </video>


                        </div>
                    </div>
                    <div className="box-product">
                        <div className="name-product">
                            <video  controls loop id="video-product">
                                <source src={video4} type="video/mp4"/>
                            </video>
                        </div>
                        <div className="descript-product" style={{padding:"0 0 0 4%"}}>
                            <h2>Transcendence and <br/>Legacy Plan</h2>
                            <h3>What does it aim for?</h3>
                            <p>We give patriarchs and heads of family the opportunity to go beyond wealth and
                                inheritance, to build and bequeath an authentic legacy with the power to impact
                                more persons and contexts.</p>
                            <h3>How does it do that?</h3>
                            <p>We apply a personalized methodology to identify deep seated personal desires,
                                visions, and ideas, striving for clarity on how people want to be remembered
                                beyond material things, through endeavors like philanthropy or cultural and social
                                development.
                            </p>
                            <p>Además, generamos conciencia sobre la importancia de considerar los principios de
                                Respeto, Responsabilidad, Solidaridad y Unión.</p>

                            <h3>What does it accomplish?</h3>
                            <p>It helps heads of families leave an indelible mark, both on their families and on
                                society.</p>
                        </div>
                    </div>


                </section>

                <FooterTest/>
            </section>
        );
    }
}

export default QueHacemos;