import React, { Component } from 'react';
import '../Home.css';
import arrow from "../../assets/Line 2.svg"
import img from "../../assets/Horizonte_030_cover.png"
import Nav from "../nav/Nav";
import FooterTest from "../footer/FooterTest";

class QuePensamos extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {
        return (
            <section >
                <Nav/>
                <div id="think" className="slide-home">
                    <div className="cubierta">
                        <div className="box-dos"></div>
                        <div className="box-white">
                            <h2 className="titulos">What we think</h2>
                            <p className="txt" >We have a firm belief that a legacy is built with many more things than just an
                                inheritance or distribution of wealth.</p>
                            <a href="#legados">
                                <img src={arrow} alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <section id="legados" className="section">
                    <div className="info-section">
                        <h2>Legacies that transcend
                        </h2>
                        <p>A legacy is built comprehensively and puts the family at the center, taking into
                            account all its contexts and characteristics.</p>
                        <p>We approach your present empathetically to make your future transcendent, with
                            the support of legal, tax, financial, and psychological counseling. We even use
                            sociology and anthropology, incorporating them in a unique methodology based on
                            the theory of family systems.</p>
                    </div>
                    <div  id="img-section" className="img-section">

                    </div>
                </section>
                <section className="section" style={{backgroundColor:"#fafafa", marginBottom:"0px"}}>
                    <div className="img-section">

                    </div>
                    <div className="info-section">
                        <h2>Book: “Cómo hacer que su herencia trascienda” (How to make your inheritance
                            last)
                        </h2>
                        <p>In our first published material, we explain the Plan Hereditario 360®, offering an
                            innovative way to address issues of inheritance through the principles of Empathy,
                            Harmony, Responsibility, and Protection.
                            <br/>
                            In profound, but engaging and agile prose, the author explains how this Plan boost
                            one’s chances of leaving a successful inheritance which will have a positive
                            influence on one’s heirs and maximize the probability of one’s wealth lasting.</p>
                    </div>

                </section>
                <FooterTest/>
            </section>
        );
    }
}

export default QuePensamos;