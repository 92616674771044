import React, { Component } from 'react';
import './Home.css';
import NavBar from "./nav/NavBar";
import FooterTest from "./footer/FooterTest";
import {Link} from "react-router-dom";
import video from "../assets/Banner sin audio_2.mp4";
import thumb from "../assets/Manifiesto thumbnail.jpg"
import manifiesto from "../assets/Manifesto (11vo corte).mp4"



class Home extends Component {

    state = {
        playing:false
    }

    componentDidMount () {
        window.scroll(0, 0)
    }

    playVideo = (e) =>{
        return this.setState({playing: true})
    }

    stopVideo = () =>{
        this.video.pause()
        return this.setState({playing:false})
    }

    render() {
        let {playing} = this.state
        return (
            <section >
                <title>Horizonte – Construimos legados que trasciende</title>

                <div onClick={this.stopVideo} className={playing?'video-overlay':'nothing'}></div>
                <NavBar/>
                <div className="slide-initial">
                    <div>
                        <div className="box-home">
                            <h2 className="titulos hm">Construimos legados que trascienden</h2>

                        </div>
                    </div>
                    <video autoPlay loop id="myVideo">

                            <source src={video} type="video/mp4"/>

                    </video>
                </div>
                <div className="manifiesto">
                    <h2>Manifiesto</h2>
                    <div className="video-manifesto">
                        <video poster={thumb} onPause={this.stopVideo} ref={video=>this.video = video} onPlay={this.playVideo} controls>
                            <source src={manifiesto} type="video/mp4"/>

                        </video>
                    </div> <p>Ayudamos a familias y empresarios a que su patrimonio trascienda y perdure por generaciones, con armonía familiar.</p>
                </div>
                <div className="products">
                    <Link to="/quienes-somos-horizonte">
                        <div className="card-w">
                            <div className="bn uno">
                                <div className="cover">
                                    <div>
                                        <h3>Quiénes somos</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>
                                    El Family Office que antepone a la familia para lograr la trascencia del patrimonio
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/que-hacemos-horizonte">
                        <div className="card-w">
                            <div className="bn dos">
                                <div className="cover">
                                    <div>
                                        <h3>Qué hacemos</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>Crear un legado que va mucho más allá de organizar testamentos y herencias</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/que-pensamos-horizonte">
                        <div className="card-w">
                            <div className="bn tres">
                                <div className="cover">
                                    <div>
                                        <h3>Qué pensamos</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>Hacemos planeación hereditaria con un método propietario,
                                    buscando construir legados con verdadero impacto y trascendencia.</p>

                            </div>
                        </div>
                    </Link>
                    <Link to="/contacto">
                        <div className="card-w">
                            <div className="bn cuatro">
                                <div className="cover">
                                    <div>
                                        <h3>Contacto</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>Acérquese a nosotros, y construyamos juntos su legado.</p>

                            </div>
                        </div>
                    </Link>
                </div>
                <FooterTest/>
            </section>
        );
    }
}

export default Home;