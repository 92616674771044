import React, { Component } from 'react';
import '../Home.css';
import arrow from "../../assets/Line 2.svg"
import logov from "../../assets/logo_vertical.png"
import guillermo from "../../assets/Guilermo_alta_color_2.jpg"
import img from "../../assets/felipe-correia-ScQngs6oO1E-unsplash.jpg"
import Nav from "../nav/Nav";
import FooterTest from "../footer/FooterTest";

const lawyers = "https://firebasestorage.googleapis.com/v0/b/segosol-demo.appspot.com/o/Best%20Lawyers%20-%20Lawyer%20Logo%20Guillermo%20Monroy.png?alt=media&token=a9941535-2fb8-4e3e-bc44-99251f0c4af9"
const isabel = "https://firebasestorage.googleapis.com/v0/b/segosol-demo.appspot.com/o/Foto%20Isabel.jpeg?alt=media&token=3fa3e27b-ffc7-4649-8853-d2a0343a17ed"

class QuienesSomos extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {
        return (
            <section >
                <Nav/>
                <div className="slide-home">
                    <div className="cubierta">
                        <div className="box-dos"></div>
                        <div className="box-white">
                            <h2 className="titulos">Who we are</h2>
                            <p className="txt" >Our mission is to help entrepreneurial families make their wealth last for
                                generations, within an atmosphere of harmony.</p>
                            <a href="#family">
                                <img src={arrow} alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="family" className="intro" >
                    <h2 className="subtitulos">More than a Family Office</h2>
                    <p className="parrafo">
                        While the traditional approach of the Family Office is focused only on managing
                        wealth and business, it ignores many aspects that develop around families.
                        Our approach is comprehensive, placing the family in question at the center of our
                        process, and makes togetherness and harmony it’s primary aim.</p>
                </div>
                <div className="founder">
                    <div className="photo-box">
                        <img className="guillermo" src={guillermo} alt=""/>
                        <h3>Guillermo Monroy</h3>
                    </div>
                    <div className="recuadro">
                        <div>
                            <p>
                                Specialized in advising Mexican and Latin American families to make their wealth
                                extend across generations, in family harmony.                            </p>
                            <hr/>
                            <p>
                                Guillermo helps them achieve successful agreements, protocols, and generational
                                transitions to avoid dissension and also to overcome internal conflicts. His support
                                goes from helping them recognize said conflicts, as well as helping to manage
                                them in order to produce a better family dynamic.                            </p>
                            <hr/>
                            <p>
                                Previously, he worked as the leader of the Estate Planning &amp; Wealth Preservation
                                Practice Group being a partner in an international firm, taking part in designing
                                equity and business structures, trusts, and Family Office services, as well as
                                inheritances and agreements among heirs for the distribution of assets.                            </p>
                            <hr/>
                            <p>
                                He holds a law degree from Anahuac University (North Campus, Mexico). He is also
                                a researcher and theorist on ways of applying the theory of family systems to the
                                needs of entrepreneurial families.                            </p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="founder">
                    <div className="photo-box">
                        <img className="guillermo" src={isabel} alt=""/>
                        <h3>Isabel Ortega</h3>
                    </div>
                    <div className="recuadro">
                        <div>
                            <p>
                            Lawyer with twenty years of experience who has had the opportunity to advise families in
                             the preparation of their hereditary plans, specialist in trusts and patrimonial structures,
                              as well as in wills and inheritance adjudications.
                            </p>
                            <p>She is a lawyer graduated from La Salle University and recently obtained a Master's degree in Corporate Law.</p>
                            <hr/>
                            <p>
                            Previously, she worked in an international firm where she established formulas and procedures so that
                             the inheritance of the people is transmitted to the heirs efficiently and in family harmony.
                            </p>
                            <hr/>
                            <p>
                            She also has experience in real estate structures and advises clients on corporate matters.        </p>
                      
                        </div>
                    </div>
                </div>
                <div className="flex-cards">

                    <div id="bx-img">

                    </div>
                    <div className="bx-info">
                        <h2 className="subtitulos" style={{textAlign:"center"}}>Recognized by many. Valued by more.</h2>
                        <div className="card">
                            <p>Our experience has helped several families build better relationships, <br/> in addition to
                                achieving a solid consolidation of wealth.</p>
                        </div>
                    </div>
                </div>
                <div className="flex-cards">
                    <div className="card-small">
                        <p>In cases of profound estrangement, we have even succeeded in substantially
                            improving family and equity prospects, both present and future.</p>
                    </div>
                    <div className="card-small">
                        <p>With the book “Cómo hacer que su herencia trascienda” (How to make your
                            inheritance last), which explains the Plan Hereditario 360® (360 Estate Plan), we
                            propose an innovative methodology for modern estate organization, with a
                            comprehensive viewpoint.</p>
                    </div>
                    <div className="card-small">
                        <p>Also, Guillermo Monroy has written several articles for specialized publications and
                            participated as a lecturer in numerous forums in Europe, the United States, and
                            Latin America.</p>

                    </div>

                </div>
                <div className="box-txt">
                    <div id="extras" className="card">
                        <p><strong>Guillermo Monroy</strong> is a member of: <br/><br/>
                            Barra Mexicana de Abogados
                            Family Firm Institute</p>
                        <p>Since the year 2014, Guillermo has earned recognition and annual mentions from
                            the magazine <strong> “Best Lawyers”.</strong></p>
                        <img src={lawyers} alt="logo-best-lawyers"/>
                      {/*  <p>We have close alliances and recognition from eminent members withing our sector,
                            which all confirm our commitment to creating transcendent legacies:</p>
                        <div className="honorables">
                            <span>Juan Osuna (Honorary Board Member)</span>
                            <span>Juan Esteban Arellano / Vestal Group</span>
                        </div>*/}
                    </div>
                </div>
                <br/>
                <br/>
                <FooterTest/>
            </section>
        );
    }
}

export default QuienesSomos;