import React, { Component } from 'react';
import './Home.css';
import arrow from "../assets/Line 2.svg"
import img from "../assets/Horizonte_030_cover.png"
import NavBar from "./nav/NavBar";
import FooterTest from "./footer/FooterTest";
import video1 from "../assets/Pequeñas Grandes Cosas (5to Corte).mp4";
import video2 from "../assets/Una Gran Idea (5to Corte).mp4";
import video3 from "../assets/Somos Nosotros (5to Corte).mp4";
import video4 from "../assets/Huellas (5to Corte).mp4"

class WhatWeDo extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {
        return (
            <section >
                <NavBar/>
                <div id="do" className="slide-home">
                    <div className="cubierta">
                        <div className="box-dos"></div>
                        <div className="box-white">
                            <h2 className="titulos">¿Qué hacemos?</h2>
                            <p className="txt" >Nuestra metodología está enfocada en lograr que un patrimonio perdure por
                                generaciones y de forma armónica.</p>
                            <a href="#proceso">
                            <img src={arrow} alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <section id="proceso" className="oferta">
                    <h2 className="titulos">Nuestro Proceso</h2>
                    <p className="txt">Con el objetivo de fomentar la unión entre actuales y futuras generaciones,
                        diseñamos un plan hereditario a la medida, que brinde tranquilidad a todos y
                        constituya un auténtico legado que trascienda lo meramente económico.</p>
                    <div className="box-padre">
                    <div className="box-product">

                        <div className="descript-product">
                            <h2>Plan Desarrollo Generacional</h2>
                            <h3>¿Qué buscamos?</h3>
                            <p>Formamos a las nuevas generaciones de una familia en materia de administración
                                del patrimonio familiar, buscando trasmitir los valores familiares y un sentido de
                                pertenencia.</p>
                            <h3>¿Cómo lo hacemos?</h3>
                            <p>Diseñamos un plan personalizado para cada familia, utilizando una metodología
                                basada en los principios de Inclusión, Identidad, Formación y Confianza.
                            </p>
                            <p>
                                Implementamos la transmisión de conocimiento y valores a través de diferentes
                                formatos: desde talleres y presentaciones, hasta mentoring, pláticas y entrevistas
                                personales.</p>
                            <h3>¿Qué alcanzamos?</h3>
                            <p>Formamos a las nuevas generaciones para que tengan conciencia de todo lo que
                                implica administrar el patrimonio familiar, así como su deber y responsabilidad de
                                conservarlo e incrementarlo.</p>
                        </div>
                        <div className="name-product">
                        <video  controls loop id="video-product">

                            <source src={video1} type="video/mp4"/>

                        </video>
                        {/* <div>
                                <h2>Plan Desarrollo Generacional</h2>
                                <hr/>
                            </div>*/}

                    </div>
                    </div>
                        <div className="box-product">
                            <div className="name-product">
                                <video  controls loop id="video-product">

                                    <source src={video2} type="video/mp4"/>

                                </video>
                                {/* <div>
                                <h2>Plan Desarrollo Generacional</h2>
                                <hr/>
                            </div>*/}

                            </div>
                            <div className="descript-product" style={{padding:"0 0 0 4%"}}>
                                <h2>Plan Hereditario 360®</h2>
                                <h3>¿Qué buscamos?</h3>
                                <p>Organizamos la herencia de una familia, estrechando los vínculos familiares para
                                    dar tanto tranquilidad a sus integrantes como efectividad a su implementación.</p>
                                <h3>¿Cómo lo hacemos?</h3>
                                <p>Aplicamos los principios de Empatía, Armonía, Responsabilidad y Protección con
                                    una novedosa metodología que integra diversas áreas del conocimiento
                                    (administración, derecho, finanzas, psicología, sociología, antropología, etc.).
                                </p>

                                <h3>¿Qué alcanzamos?</h3>
                                <p>Organizamos los procesos de la herencia de forma tal que siempre se fomente la
                                    armonía y unión familiar.</p>
                            </div>
                        </div>
                        <div className="box-product">

                            <div className="descript-product">
                                <h2>Plan Unidad Familiar</h2>
                                <h3>¿Qué buscamos?</h3>
                                <p>Fomentamos la unidad entre distintos miembros, generaciones y ramas de una
                                    misma familia para hacer del patrimonio familiar un legado sólido.</p>
                                <h3>¿Cómo lo hacemos?</h3>
                                <p>A través de talleres y sesiones buscamos alcanzar y establecer de forma formal y
                                    funcional grandes acuerdos familiares para celebrar protocolos o constituciones
                                    fundacionales.
                                </p>
                                <p>Además, generamos conciencia sobre la importancia de considerar los principios de
                                    Respeto, Responsabilidad, Solidaridad y Unión.</p>

                                <h3>¿Qué alcanzamos?</h3>
                                <p>Construimos una mejor relación entre los miembros de las distintas generaciones
                                    para que los relevos generacionales sean sencillos y efectivos, haciendo que los
                                    negocios o las inversiones se beneficien de una sola visión, fruto de una familia
                                    unida y en armonía.</p>
                            </div>
                            <div className="name-product">
                                <video  controls loop id="video-product">

                                    <source src={video3} type="video/mp4"/>

                                </video>
                                {/* <div>
                                <h2>Plan Desarrollo Generacional</h2>
                                <hr/>
                            </div>*/}

                            </div>
                        </div>
                        <div className="box-product">
                            <div className="name-product">
                                <video  controls loop id="video-product">

                                    <source src={video4} type="video/mp4"/>

                                </video>
                                {/* <div>
                                <h2>Plan Desarrollo Generacional</h2>
                                <hr/>
                            </div>*/}

                            </div>
                            <div className="descript-product" style={{padding:"0 0 0 4%"}}>
                                <h2>Plan Trascendencia y Legado</h2>
                                <h3>¿Qué buscamos?</h3>
                                <p>Le damos a los patriarcas y líderes familiares la posibilidad de ir más allá de una
                                    herencia y un patrimonio, para construir y dejar un auténtico legado con el poder
                                    de impactar a más personas y contextos.</p>
                                <h3>¿Cómo lo hacemos?</h3>
                                <p>Aplicamos una metodología de forma personalizada para identificar los deseos
                                    individuales, las visiones e ideas más profundas de una persona, buscando tener
                                    claridad sobre cómo le gustaría ser recordado más allá de los bienes materiales,
                                    como filantropía o desarrollo cultural y social.
                                </p>
                                <p>Además, generamos conciencia sobre la importancia de considerar los principios de
                                    Respeto, Responsabilidad, Solidaridad y Unión.</p>

                                <h3>¿Qué alcanzamos?</h3>
                                <p>Nuestra experiencia permite a líderes familiares dejar una huella imborrable, tanto
                                    en su familia como en la sociedad.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <FooterTest/>
            </section>
        );
    }
}

export default WhatWeDo;