import React, { Component } from 'react';
import "./NavBar.css"
import {Link} from "react-router-dom"
import logo from "../../assets/logo_horizontal.png"
import FontAwesome from "react-fontawesome";


class Nav extends Component {

    state = {
        show:false
    }

    render() {
        let {show} = this.state
        return (
            <div>
                <div className="nav">
                    <Link to="/">
                        <img src={logo} alt=""/>
                    </Link>
                    <div className="pestanas">
                        <Link to="/home">
                            <p>Home</p>
                        </Link>
                        <Link to="/what-we-are">
                            <p>Who we are</p>
                        </Link>
                        <Link to="/what-we-think">
                            <p>What we think</p>
                        </Link>
                        <Link to="/what-we-do">
                            <p>What we do</p>
                        </Link>
                        <Link to="/contact">
                            <p>Contact us</p>
                        </Link>
                    </div>
                    <div
                        onClick={()=>this.setState({show:!show})}
                        className={"anvorguesa"}>
                        <FontAwesome name="bars" />
                    </div>


                </div>
                <div className={show ? "mobile-menu":"mobile-menu menu-off"}>
                    <Link to="/what-we-are">
                        <p>Who we are</p>
                    </Link>
                    <hr/>
                    <Link to="/what-we-think">
                        <p>What we think</p>
                    </Link>
                    <hr/>
                    <Link to="/what-we-do">
                        <p>What we do</p>
                    </Link>
                    <hr/>
                    <Link to="/contact">
                        <p>Contact us</p>
                    </Link>
                </div>
            </div>

        );
    }
}

export default Nav;