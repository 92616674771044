import React, { Component } from 'react';
import './Home.css';
import arrow from "../assets/Line 2.svg"
import img from "../assets/Horizonte_030_cover.png"
import NavBar from "./nav/NavBar";
import FooterTest from "./footer/FooterTest";

class WhatWeThink extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {
        return (
            <section >
                <NavBar/>
                <div id="think" className="slide-home">
                    <div className="cubierta">
                        <div className="box-dos"></div>
                        <div className="box-white">
                            <h2 className="titulos">¿Qué pensamos?</h2>
                            <p className="txt" >Tenemos la firme convicción de que un legado se construye con muchas más
                                cosas que una herencia o la distribución del patrimonio.</p>
                            <a href="#legados">
                                <img src={arrow} alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <section id="legados" className="section">
                    <div className="info-section">
                        <h2 className="subtitulos">Legados que trascienden
                        </h2>
                        <p>Un legado se construye de forma integral y pone al centro a la familia,
                            contemplando a ésta en todas sus acepciones, contextos y características.</p>
                        <p>Abordamos el presente de cada familia de forma empática para hacer de su futuro
                            uno trascendente, brindándoles asesoría integral que incluye lo jurídico, financiero,
                            psicológico; incluso hacemos uso de la sociología y antropología, integrándolas a
                            una metodología propia basada en la teoría del sistema familiar.</p>
                    </div>
                    <div  id="img-section" className="img-section">

                    </div>
                </section>
                <section className="section reverse" style={{backgroundColor:"#fafafa", marginBottom:"0px"}}>
                    <div className="img-section">

                    </div>
                    <div className="info-section">
                        <h2 className="subtitulos">Libro “Cómo hacer que su herencia trascienda”
                        </h2>
                        <p>En esta publicación nuestro Managing Partner Guillermo Monroy detalla el Plan Hereditario 360®, planteando
                            una novedosa forma de abordar el tema hereditario a través de los principios de
                            Empatía, Armonía, Responsabilidad y Protección.
                            En una profunda, pero interesante y dinámica prosa, el autor explica cómo este
                            Plan incrementa las probabilidades de tener una herencia exitosa que influya
                            positivamente en sus herederos y propicie que su patrimonio perdure.</p>
                    </div>

                </section>
                <FooterTest/>
            </section>
        );
    }
}

export default WhatWeThink;