import React, { Component } from 'react';
import './Home.css';
import Nav from "./nav/Nav";
import video from "../assets/Banner sin audio_2.mp4";
import FooterTest from "./footer/FooterTest";
import {Link} from "react-router-dom";
import thumb from "../assets/Manifiesto thumbnail.jpg";
import manifiesto from "../assets/Manifesto (11vo corte).mp4"

class Inicio extends Component {

    state = {
        playing:false
    }

    componentDidMount () {
        window.scroll(0, 0)
    }

    playVideo = (e) =>{
        return this.setState({playing: true})
    }

    stopVideo = () =>{
        this.video.pause()
        return this.setState({playing:false})
    }

    render() {
        let {playing} = this.state
        return (
            <section >
                <title>Horizonte – We build legacies that transcend</title>

                <meta title="Horizonte – We build legacies that transcend"/>
                <div onClick={this.stopVideo} className={playing?'video-overlay':'nothing'}></div>
                <Nav/>
                <div className="slide-initial">
                    <div>
                        <div className="box-home">
                            <h2 className="titulos hm">We build legacies that transcend</h2>

                        </div>
                    </div>
                     <video autoPlay loop id="myVideo">
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>
                <div className="manifiesto">
                    <h2>Manifest</h2>
                    <div className="video-manifesto">
                        <video poster={thumb} onPause={this.stopVideo} ref={video=>this.video = video} onPlay={this.playVideo} controls>
                            <source src={manifiesto} type="video/mp4"/>
                        </video>

                    </div> <p>We help families and business people to create long-lasting, trascending legacies
                    through family welfare and harmony.</p>
                </div>
                <div className="products">
                    <Link to="/quienes-somos-horizonte">
                        <div className="card-w">
                            <div className="bn uno">
                                <div className="cover">
                                    <div>
                                        <h3>Who we are</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>We're the first buro that provides coaching and Family Office
                                    services with a holistic and multidisciplinary approach.</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/que-hacemos-horizonte">
                        <div className="card-w">
                            <div className="bn dos">
                                <div className="cover">
                                    <div>
                                        <h3>What we think</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>Creating a lasting legacy means so much more than just
                                    preparing a will or organizing an inheritance.</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/que-pensamos-horizonte">
                        <div className="card-w">
                            <div className="bn tres">
                                <div className="cover">
                                    <div>
                                        <h3>What we do</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>With our own proprietary method, we seek to build lasting
                                    legacies that have true impact.</p>

                            </div>
                        </div>
                    </Link>
                    <Link to="/contacto">
                        <div className="card-w">
                            <div className="bn cuatro">
                                <div className="cover">
                                    <div>
                                        <h3>Contact</h3>
                                        <hr className="line-w"/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-w-info">
                                <p>Get in touch with us, so we can build your legacy, together.</p>

                            </div>
                        </div>
                    </Link>
                </div>
                <FooterTest/>
            </section>
        );
    }
}

export default Inicio;