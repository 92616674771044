import React, { Component } from 'react';
import './Home.css';
import arrow from "../assets/Line 2.svg"
import logov from "../assets/logo_vertical.png"
import guillermo from "../assets/Guilermo_alta_color_2.jpg"
import img from "../assets/felipe-correia-ScQngs6oO1E-unsplash.jpg"
import Footer from "./footer/Footer";
import NavBar from "./nav/NavBar";
import FooterTest from "./footer/FooterTest";

const lawyers = "https://firebasestorage.googleapis.com/v0/b/segosol-demo.appspot.com/o/Best%20Lawyers%20-%20Lawyer%20Logo%20Guillermo%20Monroy.png?alt=media&token=a9941535-2fb8-4e3e-bc44-99251f0c4af9"
const isabel = "https://firebasestorage.googleapis.com/v0/b/segosol-demo.appspot.com/o/Foto%20Isabel.jpeg?alt=media&token=3fa3e27b-ffc7-4649-8853-d2a0343a17ed"

class WhatWeAre extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {
        return (
            <section >
                <NavBar/>
                <div className="slide-home">
                    <div className="cubierta">
                        <div className="box-dos"></div>
                        <div className="box-white">
                            <h2 className="titulos">¿Quiénes somos?</h2>
                            <p className="txt" >Nuestra misión es ayudar a familias empresarias para que su patrimonio
                                perdure por generaciones en armonía familiar.</p>
                            <a href="#family">
                                <img src={arrow} alt="arrow"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="family" className="intro" >
                    <h2 className="subtitulos">Más que un Family Office</h2>
                    <p className="parrafo">
                        Proponemos un enfoque integral, que pone la familia al centro, haciendo de su
                        unión y armonía el objetivo principal, mientras que el enfoque tradicional de un
                        Family Office está centrado únicamente en administrar el patrimonio y la empresa,
                        desentendiéndose de la familia.</p>
                </div>
                <div className="founder">
                    <div className="photo-box">
                        <img className="guillermo" src={guillermo} alt=""/>
                        <h3>Guillermo Monroy</h3>
                    </div>
                    <div className="recuadro">
                        <div>
                            <p>
                                Consultor especializado en asesorar a familias mexicanas y latinoamericanas para lograr que su patrimonio trascienda por generaciones, en armonía familiar.
                            </p>
                            <p>Es creador de la novedosa metodología del Plan Hereditario 360</p>
                            <hr/>
                            <p>
                                Guillermo les ayuda a alcanzar acuerdos, protocolos y transiciones generacionales exitosas para evitar desavenencias y alcanzar la resolución de conflictos internos. En este sentido, su apoyo va desde ayudarlos a reconocer dichos conflictos hasta gestionarlos para lograr una mejor dinámica familiar.
                            </p>
                            <hr/>
                            <p>
                                Previamente, fue socio de una firma internacional en la cual fue líder del área de Servicios Patrimoniales y Sucesorios, participando en el diseño de estructuras patrimoniales y empresariales, fideicomisos y servicios de Family Office, así como adjudicaciones hereditarias y acuerdos entre herederos para la distribución de los bienes.
                            </p>
                            <hr/>
                            <p>
                                Es abogado por la Universidad Anáhuac México, Campus Norte. También es investigador y postulador teórico sobre cómo aplicar la teoría de sistemas familiares en las necesidades de las familias empresarias.
                            </p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className="founder">
                    <div className="photo-box">
                        <img className="guillermo" src={isabel} alt=""/>
                        <h3>Isabel Ortega</h3>
                    </div>
                    <div className="recuadro">
                        <div>
                            <p>
                                Abogada con veinte años de experiencia quien ha tenido la oportunidad de asesorar a
                                familias en la elaboración de sus planes hereditarios, especialista en fideicomisos y
                                estructuras patrimoniales, así como en testamentos y adjudicaciones hereditarias.
                            </p>
                            <p>Es abogada egresada por la Universidad La Salle y recientemente obtuvo el grado de
                                Maestría en Derecho Corporativo.</p>
                            <hr/>
                            <p>
                                Previamente trabajó en una firma internacional en donde estableció fórmulas y
                                procedimientos para que la herencia de las personas sea transmitida a los herederos de
                                manera eficiente y en armonía familiar.
                            </p>
                            <hr/>
                            <p>
                            También tiene experiencia en estructuras inmobiliarias y asesora a clientes en materia
                            corporativa.                            </p>
                      
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className="flex-cards">

                   {/* <div id="bx-img">

                    </div>*/}
                    <div className="bx-info">
                        <h2 className="subtitulos" style={{textAlign:"center"}}>Reconocidos por muchos. Valorados por incluso más.</h2>
                        <div className="card">
                            <p>Nuestra experiencia ha hecho posible que numerosas familias tengan mejores relaciones, <br/> además de una sólida consolidación patrimonial.</p>
                        </div>
                    </div>
                </div>
                <div className="flex-cards">
                    <div className="card-small">
                        <p>En casos de distanciamiento profundo, incluso hemos logrado una notoria mejoría en el panorama familiar y patrimonial presente y futuro.</p>
                    </div>
                    <div className="card-small">
                        <p>Con el libro “Cómo hacer que su herencia trascienda”, que expone nuestro Plan
                            Hereditario 360®, proponemos una novedosa metodología para alcanzar una
                            organización hereditaria moderna, con una perspectiva integral.</p>
                    </div>
                    <div className="card-small">
                        <p>Adicionalmente, Guillermo Monroy ha escrito varios artículos para publicaciones
                            especializadas y participado como conferencista en numerosos foros en Europa,
                            Estados Unidos y Latinoamérica.</p>

                    </div>

                </div>
                <div className="box-txt">
                    <div id="extras" className="card">
                    <p><strong>Guillermo Monroy</strong> forma parte de: <br/><br/>
                        La Barra Mexicana de Abogados
                        The Family Firm Institute</p>
                    <p>Desde el año 2014, Guillermo ha obtenido reconocimiento y mención anual por
                        parte la revista <strong> “Best Lawyers”.</strong></p>
                        <img src={lawyers} alt=""/>
                   {/* <p>Para validar nuestro compromiso de crear legados trascendentes, hemos formado
                        alianzas con miembros reconocidos de nuestro sector:</p>
                        <div className="honorables">
                            <span>Juan Osuna (Consejero Honorario)</span>
                            <span>Juan Esteban Arellano / Vestal Group</span>
                        </div>*/}
                    </div>
                </div>
                <br/>
                <br/>
                <FooterTest/>
            </section>
        );
    }
}

export default WhatWeAre;