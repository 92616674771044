import React, { Component } from 'react';
import './Home.css';
import {Link} from "react-router-dom"
import FontAwesome from "react-fontawesome";
import logo from "../assets/logo_vertical_claro_blanco.png"

class Slide extends Component {
    componentDidMount () {
        window.scroll(0, 0)
    }
    render() {
        return (
            <div className="video-box">
                <div className="backi">
                    <div className="welcome">
                        <img src={logo} alt=""/>
                        <p>
                            Family Office
                        </p>

                        <br/>
                        <div className="lenguajes">
                            <Link to="/home">
                                <button className="btn-white">English</button>
                            </Link>
                            <Link to="/inicio">
                                <button className="btn-white">Español</button>
                            </Link>
                        </div>
                        <div className="slide-redes">
                            <a href="https://www.linkedin.com/company/horizonte/about/" target="_blank">
                                <FontAwesome name="linkedin"/>
                            </a>

                        </div>
                    </div>
                   {/* <video autoPlay loop id="myVideo">
                        <source src={video} type="video/mp4"/>
                    </video>*/}
               </div>


            </div>
        );
    }
}

export default Slide;